import React from "react";

import { useForm } from "../../context/forms";
import * as C from "./styles";
import { convertToSlug, maskDateToPt } from "../../utils/functions";

export const Input = ({ field_id, type, index, sectionIndex, allows_notes = false }: Props) => {
    const { form, changeForm } = useForm();

    const [value, setValue] = React.useState(form?.sections ? form?.sections[sectionIndex]?.fields[index]?.values?.value : "");
    const [note, setNote] = React.useState(form?.sections ? form?.sections[sectionIndex]?.fields[index]?.values?.note : "");

    const changeValue = (text: string, type = "") => {
        let _record = { ...form };

        if (form && Array.isArray(_record.sections) && _record.sections[sectionIndex]) {
            let value = text;

            if (type === "date") value = maskDateToPt(text ?? "");
            else if (type === "email") value = value;
            else value = convertToSlug(value);

            setValue(value?.toUpperCase());

            _record.sections[sectionIndex].fields[index].values = {
                ..._record.sections[sectionIndex].fields[index].values,
                value: value?.toUpperCase(),
                field_id: field_id,
            };
        }

        changeForm(_record);
    };

    const changeNote = (text: string) => {
        let _record = { ...form };

        if (form && Array.isArray(_record.sections) && _record.sections[sectionIndex]) {
            _record.sections[sectionIndex].fields[index].values = { ..._record.sections[sectionIndex].fields[index].values, note: text, field_id: field_id };
        }

        changeForm(_record);
    };

    return (
        <C.Container>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", flex: 1 }}>
                <C.Input
                    onBlur={e => changeValue(e.target.value, type.split("|")[0])}
                    onChange={e => changeValue(e.target.value, type.split("|")[0])}
                    autoFocus={index === 0}
                    type={type.split("|")[0] === "date" ? "text" : type.split("|")[0]}
                    maxLength={type.split("|").length > 0 ? parseInt(type.split("|")[1]) : 255}
                    value={value}
                    id={`field-${field_id}`}
                />
                {allows_notes ? (
                    <C.InputNote
                        placeholder="Observações"
                        onBlur={e => changeNote(e.target.value)}
                        onChange={e => setNote(e.target.value)}
                        type={"text"}
                        value={note}
                    />
                ) : null}
            </div>
        </C.Container>
    );
};

type Props = {
    type: string;
    index: number;
    sectionIndex: number;
    field_id: number;
    value?: any;
    allows_notes?: boolean;
};
