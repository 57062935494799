import * as C from "./styles";

import React from "react";
import swal from "sweetalert";

import { useHistory, useParams } from "react-router-dom";

/** context */
import { useForm } from "../../context/forms";

/** component */
import { Theme } from "../../components/Theme/intex";
import { Display } from "./../../components/Display";
import { Input } from "../../components/Input";
import { Radio } from "../../components/Radio";

/** services */
import api from "../../services/api";

/** utils */
import loading_alert from "../../utils/alerts/loading";
import error_alert from "../../utils/alerts/error";

export const Home = () => {
    const params: { cuid: string } = useParams();

    const { addForm, form, changeForm, saveForm } = useForm();

    const handleNextStep = () => {
        if (!form?.sections) return;

        const nextId = form.sections.find((item, index) => index > (form.activeSection ?? 0) && item.show !== false)?.id;

        if (nextId) {
            const nextIndex = form.sections.findIndex(i => i.id === nextId);
            changeForm({ activeSection: nextIndex });

            setTimeout(() => {
                document.body?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth", // or can get `auto` variable
                });
                document?.body?.scrollTo(0, 0);
                document.body.scrollTop = 0;
            }, 2);
        }
    };

    const handleBackStep = () => {
        if (!form?.sections) return;

        let prevIndex = 0;

        for (let i = form.sections.length - 1; i >= 0; i--) {
            if (form.sections[i].show !== false) {
                if (i < (form.activeSection ?? 0)) {
                    prevIndex = i;

                    break;
                }
            }
        }

        changeForm({ activeSection: prevIndex });
    };

    const handleSendForm = () => {
        if (form && form?.sections) {
            form?.sections[form.activeSection ?? 0].fields.map((field: iField) => {
                if (field.is_required && field.show !== false) {
                    if (!field.values?.value || field.values?.value === "") {
                        error_alert(`Campo obrigatório.`, `O campo "${field.name}" é obrigatório.`, () =>
                            document.getElementById(`field-${field.id}`)?.focus(),
                        );

                        return null;
                    }
                }

                return null;
            });
        }

        saveForm();
    };

    const renderNextButton = () => {
        if (form?.sections) {
            if ((form?.activeSection ?? 0) < form?.sections?.length - 1) {
                return (
                    <button className="next" onClick={handleNextStep}>
                        Próximo
                    </button>
                );
            }
        }
    };

    const renderSendButton = () => {
        if (form?.sections) {
            if ((form?.activeSection ?? 0) === form?.sections?.length - 1) {
                return (
                    <button className="send" onClick={handleSendForm}>
                        Enviar formulário
                    </button>
                );
            }
        }
    };

    const renderPrevButton = () => {
        if (form?.sections) {
            if ((form.activeSection ?? 0) > 0) {
                return (
                    <button className="back" onClick={handleBackStep}>
                        Voltar
                    </button>
                );
            }
        }
    };

    const loadLoadForm = (form_id: number, link_id: number) => {
        loading_alert("Carregando formulário...", "Por favor aguarde");

        api.get(`/forms/public/${form_id}`)
            .then(response => {
                const data: iForm = response.data;

                if (!data.id) throw new Error("Formulário inválido.");
                if (swal && swal.getState && swal.close && swal.getState().isOpen) swal.close();

                addForm({ ...data, link_id });
            })
            .catch(error => {
                console.log(error);
                error_alert("Oops!", "Formulário inválido!", () => {}, { buttons: [false], closeOnEsc: false, closeOnClickOutside: false });
            });
    };

    const loadLink = () => {
        loading_alert("Carregando configurações...", "Por favor aguarde");

        api.get(`/public/links?cuid=${params.cuid}`)
            .then(response => {
                const data: iLink = response.data;

                if (!data.form_id) throw new Error("Formulário não localizado.");

                loadLoadForm(data.form_id, data.id);
            })
            .catch(error => {
                console.log(error);
                error_alert("Oops!", "Link inválido ou expirado!", () => {}, { buttons: [false], closeOnEsc: false, closeOnClickOutside: false });
            });
    };

    const renderFieldByType = (field: iField, fieldIndex: number) => {
        if (field.type.type === "richtext") {
            return <Display description={field.help} key={`field-${field.id}`} />;
        } else if (
            field.type.type === "text|100" ||
            field.type.type === "text|255" ||
            field.type.type === "number" ||
            field.type.type === "date" ||
            field.type.type === "datetime-local" ||
            field.type.type === "time" ||
            field.type.type === "email"
        ) {
            return (
                <Input
                    field_id={field.id}
                    index={fieldIndex}
                    key={`field-${field.id}`}
                    sectionIndex={form?.activeSection ?? 0}
                    type={field.type.type}
                    allows_notes={field.allows_notes}
                />
            );
        } else if (field.type.type === "radio") {
            return <Radio field={field} sectionIndex={form?.activeSection ?? 0} index={fieldIndex} />;
        }
    };

    React.useEffect(() => {
        if (params.cuid) {
            loadLink();
        } else {
            error_alert("Oops!", "Parâmetro inválido!", () => {}, { buttons: [false], closeOnEsc: false, closeOnClickOutside: false });
        }
    }, [params.cuid]);

    return (
        <Theme>
            {form ? (
                <C.Container>
                    <p className="passo">{/* Passo {(form?.activeSection ?? 0) + 1}/{form?.sections?.length ?? 0 - 1} */}</p>

                    {form && form.sections ? (
                        <>
                            <h2>{form?.sections[form.activeSection ?? 0].name}</h2>
                            <p>{form?.sections[form.activeSection ?? 0].description}</p>

                            {form?.sections[form.activeSection ?? 0].fields.map((field, index) => (
                                <>
                                    {field.show !== false ? (
                                        <>
                                            <label style={{ fontWeight: "bold" }}>
                                                {field.name}
                                                {field.is_required ? <span style={{ color: "red" }}> *</span> : ""}
                                            </label>
                                            <>{renderFieldByType(field, index)}</>
                                        </>
                                    ) : null}
                                </>
                            ))}
                        </>
                    ) : null}

                    <div className="buttons">
                        {renderPrevButton()}
                        {renderNextButton()}
                        {renderSendButton()}
                    </div>
                </C.Container>
            ) : null}
        </Theme>
    );
};

export default Home;
